import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class Branch {

	getBaseURL() {
		return config.SERVER_URL
	}

	getItems(start) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'branches/index/' + start;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	saveItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'branches/add_store/';
		const formData = new FormData();
		formData.append('branch_type', postObj.isAdmin.value);
		formData.append('code', postObj.storeCode);
		formData.append('mall', postObj.mallName);
		formData.append('telephone', postObj.telephoneNo);
		formData.append('contact', postObj.contactNo);
		formData.append('address', postObj.address);
		formData.append('city', postObj.city);
		formData.append('province', postObj.province);
		formData.append('region', postObj.region);
		formData.append('postalcode', postObj.postalCode);
		formData.append('ll', postObj.ll);
		formData.append('sqft', postObj.sqft);
		formData.append('password', postObj.password);
		formData.append('taxCriteria1', postObj.taxCriteria1);
		formData.append('taxCriteria2', postObj.taxCriteria2);
		formData.append('taxValue1', postObj.taxValue1);
		formData.append('taxValue2', postObj.taxValue2);
		formData.append('email_address', postObj.emailAddress);
		formData.append('cost_center', postObj.costCenter);
		formData.append('regular_hrs', postObj.regularWeeklyHrs);
		formData.append('daily_hrs', postObj.regularDailyHrs);
		formData.append('stat_holiday_rate', postObj.statholidayRate);
		formData.append('overtime_rate', postObj.overTimeRate);
		formData.append('opening_date', postObj.storeOpeningDate);
		formData.append('overtime', postObj.overTime);
		formData.append('birhtday', postObj.birthDay);
		formData.append('stat_hol', postObj.statHoliday);
		formData.append('statwork', postObj.statWork);
		formData.append('meetcon', postObj.meetCon);
		formData.append('personel', postObj.personal);
		formData.append('sickhrs', postObj.sickHrs);
		formData.append('vacations', postObj.vacations);
		formData.append('bereavmt', postObj.breavmt);
		formData.append('training', postObj.training);
		formData.append('tax_code', postObj.taxNo);
		formData.append('enable_email_sms', postObj.enableSms.value);
		formData.append('store_magic', postObj.storeMagic);
		formData.append('rating_desc', postObj.qrCodeTagLine);
		formData.append('rating_link', postObj.qrCodeLink);
		formData.append('breakHrs', postObj.breakHrs);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	updateItem(postObj) {
		
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'branches/edit_store/';
		const formData = new FormData();
		formData.append('branch_type', postObj.isAdmin.value);
		formData.append('code', postObj.storeCode);
		formData.append('mall', postObj.mallName);
		formData.append('telephone', postObj.telephoneNo);
		formData.append('contact', postObj.contactNo);
		formData.append('address', postObj.address);
		formData.append('city', postObj.city);
		formData.append('province', postObj.province);
		formData.append('region', postObj.region);
		formData.append('postalcode', postObj.postalCode);
		formData.append('ll', postObj.ll);
		formData.append('sqft', postObj.sqft);
		formData.append('password', postObj.password);
		formData.append('tax_criteria_1', postObj.taxCriteria1);
		formData.append('tax_criteria_2', postObj.taxCriteria2);
		formData.append('tax_value_1', postObj.taxValue1);
		formData.append('tax_value_2', postObj.taxValue2);
		formData.append('email_address', postObj.emailAddress);
		formData.append('cost_center', postObj.costCenter);
		formData.append('regular_hrs', postObj.regularWeeklyHrs);
		formData.append('daily_hrs', postObj.regularDailyHrs);
		formData.append('stat_holiday_rate', postObj.statholidayRate);
		formData.append('overtime_rate', postObj.overTimeRate);
		formData.append('opening_date', postObj.storeOpeningDate);
		formData.append('overtime', postObj.overTime);
		formData.append('birhtday', postObj.birthDay);
		formData.append('stat_hol', postObj.statHoliday);
		formData.append('statwork', postObj.statWork);
		formData.append('meetcon', postObj.meetCon);
		formData.append('personel', postObj.personal);
		formData.append('sickhrs', postObj.sickHrs);
		formData.append('vacations', postObj.vacations);
		formData.append('bereavmt', postObj.breavmt);
		formData.append('training', postObj.training);
		formData.append('tax_code', postObj.taxNo);
		formData.append('enable_email_sms', postObj.enableSms.value);
		formData.append('store_magic', postObj.storeMagic);
		formData.append('rating_desc', postObj.qrCodeTagLine);
		formData.append('rating_link', postObj.qrCodeLink);
		formData.append('breakHrs', postObj.breakHrs);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	changeStorePin(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'branches/change_pin/';
		const formData = new FormData();
		formData.append('store_id', postObj.storeID);
		formData.append('old_pin', postObj.oldPin);
		formData.append('new_pin', postObj.newPin);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	showStorePin(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'branches/verify_store_pin/';
		const formData = new FormData();
		formData.append('store_id', postObj.storeIDView);
		formData.append('manager_id', postObj.storeManagerPin);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getItem(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'branches/single_store/' + data.code;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	changeStatus(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'branches/change_status/' + data.status + '/' + data.storeID;
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getSearchedBranches(keyword) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'branches/search_for_branches/';

		const formData = new FormData();
		formData.append('keyword', keyword);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}