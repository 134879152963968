import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';


export default class PaymentService {

	getBaseURL() {
		return config.SERVER_URL;
	}

	terminalRequest(transString) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'injenico_payment/add_terminal_payment/';

		const formData = new FormData();
		formData.append('trans_string', JSON.stringify(transString));

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}