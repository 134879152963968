import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';
export default class Coupon {

	getItems(start) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'coupon/index/' + start;
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getServicesAndDepartments() {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'items/fetch_services_and_departments/';
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getBaseURL() {
		return config.SERVER_URL;
	}

	saveItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'coupon/add_discount/';
		const formData = new FormData();
		formData.append('discount_name', postObj.name);
		formData.append('discount_barcode', postObj.barcode);
		formData.append('type', postObj.copounfor);
		formData.append('service_id', postObj.services.id);
		formData.append('discount_percentage', postObj.percentage);
		formData.append('discount_method', postObj.discountMethod.value);
		formData.append('discount_description', postObj.description);
		formData.append('starts_date', postObj.startdate);
		formData.append('end_date', postObj.enddate);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	updateItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'coupon/edit_discount/';

		const formData = new FormData();
		if (postObj.services) {
			formData.append('service_id', postObj.services.id);
			// formData.append('service_id','0');
		} else {
			formData.append('service_id', '0');
		}
		formData.append('edit_discount_id', postObj.id);
		formData.append('discount_name', postObj.name);
		formData.append('discount_barcode', postObj.barcode);
		formData.append('type', postObj.copounfor);
		formData.append('discount_percentage', postObj.percentage);
		formData.append('discount_method', postObj.discountMethod.value);
		formData.append('description', postObj.description);
		formData.append('starts_date', postObj.startdate);
		formData.append('end_date', postObj.enddate);


		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getItem(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'coupon/single_coupon/' + data.id;
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	changeStatus(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'coupon/change_status/' + data.status + '/' + data.id;
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getSearchedCoupons(keyword) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'coupon/search_for_coupon/';

		const formData = new FormData();
		formData.append('keyword', keyword);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}