import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class PriceListService {

	getBaseURL() {
		return config.SERVER_URL;
	}

	//GET SCHEDULE DATA
	getPriceItemList() {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'price_list/price_item/';

		return instance()(
			{
				url: api,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//ITEM LIST
	getItemList(serviceID,departmentID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'price_list/fetch_itemlist/';
		const formData = new FormData();
		formData.append('service_id', serviceID);
		formData.append('department_id', departmentID);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	//ITEM LIST SAVE
	saveItemPrice(serviceID,departmentID,scheduleID,itemID,price) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'price_list/add_price/';
		const formData = new FormData();
		formData.append('service_id', serviceID);
		formData.append('department_id', departmentID);
		formData.append('schedule_id', scheduleID);
		formData.append('item_id', itemID);
		formData.append('price', price);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//ITEM LIST SAVE
	applyOption(serviceID,departmentID,scheduleID,optionName,optionValue) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'price_list/update_price_list_option/';
		const formData = new FormData();
		formData.append('service_id', serviceID);
		formData.append('department_id', departmentID);
		formData.append('schedule_id', scheduleID);
		formData.append('percentage_name', optionName);
		formData.append('percentage_value', optionValue);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//SEARCH FOR PRICE LIST
	searchPriceList(serviceID,departmentID,scheduleID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'price_list/search_condition_price_list/';
		const formData = new FormData();
		formData.append('services', serviceID);
		formData.append('department', departmentID);
		formData.append('schedules', scheduleID);
		return instance()(
		{
			method: 'post',
			url: api,
			data: formData,
		}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	//UPDATE THE PRICE LIST
	updatePriceList(scheduleID,priceList) {
	
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'price_list/update_condition_price_list/';
		const formData = new FormData();
		formData.append('price_list', JSON.stringify(priceList));
		formData.append('schedule_id', scheduleID);
		return instance()(
		{
			method: 'post',
			url: api,
			data: formData,
		}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//ADD POPUP
	addPopup(scheduleID,popupID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'price_list/save_popups_item/';
		const formData = new FormData();
		formData.append('schedule_id', scheduleID);
		formData.append('popup_condition', popupID);
		return instance()(
		{
			method: 'post',
			url: api,
			data: formData,
		}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	copyPriceList(scheduleIDFrom,scheduleIDTo,optApply) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'price_list/copy_schedule/';
		const formData = new FormData();
		formData.append('from_schedule', scheduleIDFrom);
		formData.append('to_schedule', scheduleIDTo);

		if (optApply == 'all') {
			formData.append('opt_apply', optApply[0]);
			formData.append('opt_apply_id', optApply[0]);
		} else if (optApply == 'all_condition') {
			formData.append('opt_apply', optApply[0]);
			formData.append('opt_apply_id', optApply[0]);
		} else if (optApply) {
			formData.append('opt_apply', optApply.type);
			formData.append('opt_apply_id', optApply.id);
		}
		
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}