import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class ApprovalService {

	getBaseURL() {
		return config.SERVER_URL;
	}

	getAssociateShifts(weekDate) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'manage_shifts/index/';
		const formData = new FormData();
		formData.append('week_date', weekDate);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getAssociateLogin(associateID, weekStart, weekEnd) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'manage_shifts/associate_login/';
		const formData = new FormData();
		formData.append('employee_id', associateID);
		formData.append('week_start', weekStart);
		formData.append('week_end', weekEnd);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}


	postCheckListApprove(scheduleID, list, managerApproved) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'manage_shifts/associate_approve_hrs/';
		const formData = new FormData();
		formData.append('schedule_id', scheduleID);
		formData.append('manager_approved', managerApproved);
		formData.append('list', JSON.stringify(list));
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	approveHours(approvedHours,managerID,associateID,associateName,associateJobClass,weekEndingDate,weekNo,savedLoggedID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'manage_shifts/save_approved_hrs/';
		const formData = new FormData();
		formData.append('approved_hours', JSON.stringify(approvedHours));
		formData.append('branch_manager_id', managerID);
		formData.append('associate_id', associateID);
		formData.append('associate_name', associateName);
		formData.append('associate_job', associateJobClass);
		formData.append('week_end_date', weekEndingDate);
		formData.append('week_no', weekNo);
		formData.append('saved_logged_id', savedLoggedID);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			});
	}
	
	adjustApproveHours(hours,managerID,savedLoggedID,associateName,activeAssociateID,activeAssociateJobClass) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'manage_shifts/save_adjusted_approved_hrs/';
		const formData = new FormData();
		formData.append('approved_hours', JSON.stringify(hours));
		formData.append('branch_manager_id', managerID);
		formData.append('saved_logged_id', savedLoggedID);
		formData.append('associate_name', associateName);
		formData.append('associate_id', activeAssociateID);
		formData.append('job_class', activeAssociateJobClass);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			});
	}

	addAssociateLogin(loginTime, employeeID, managerID, logoutTime, loginDate, weekStart , weekEnd) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'attendence/add_login_timing/';
		const formData = new FormData();
		formData.append('login_time', loginTime);
		formData.append('manager_id', managerID);
		formData.append('employee_id', employeeID);
		formData.append('logout_time', logoutTime);
		formData.append('login_date', loginDate);
		formData.append('week_start', weekStart);
		formData.append('week_end', weekEnd);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			});
	}

	updateAssociateLogin(loginTime, attendenceID, managerID, logoutTime, loginDate, weekStart , weekEnd) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'attendence/update_login_timing/';
		const formData = new FormData();
		formData.append('login_time', loginTime);
		formData.append('manager_id', managerID);
		formData.append('attendence_id', attendenceID);
		formData.append('logout_time', logoutTime);
		formData.append('login_date', loginDate);
		formData.append('week_start', weekStart);
		formData.append('week_end', weekEnd);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
		.catch((e) => ExceptionHandling.HandleErrors(e))
		.finally(() => {
			store.dispatch(ActionTypes.PROGRESS_BAR, false);
		})
	}
	
	deleteAssociateLogin(attendenceID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'attendence/delete_timings/';
		const formData = new FormData();
		formData.append('attendence_id', attendenceID);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
		.catch((e) => ExceptionHandling.HandleErrors(e))
		.finally(() => {
			store.dispatch(ActionTypes.PROGRESS_BAR, false);
		})
	}

	publishSchedule(schedule,associateID,managerID,scheduleID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee_shift/insert_approval_shift/';
		const formData = new FormData();	
		formData.append('schedule', JSON.stringify(schedule));
		formData.append('associateID', associateID);
		formData.append('br_manager_id', managerID);
		formData.append('scheduleID', scheduleID);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}