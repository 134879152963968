import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class Schedule {

	getBaseURL() {
		return config.SERVER_URL;
	}

	getScheduleDetails(searchDate) {
		//SHOW LOADING
	const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee_shift/index/';
		const formData = new FormData();
		formData.append('search_date', searchDate);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	publishSchedule(schedule, weekEndDate, projSales) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee_shift/add_schedule/';
		const formData = new FormData();
		formData.append('schedule', JSON.stringify(schedule));
		formData.append('week_end_date', weekEndDate);
		formData.append('proj_schedule', JSON.stringify(projSales));
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	copySchedule(to, from) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee_shift/copy_schedule/';
		const formData = new FormData();
		formData.append('to_date', to);
		formData.append('from_date', from);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	deleteSchedule(date) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee_shift/delete_schedule/';
		const formData = new FormData();
		formData.append('date', date);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getWeekList() {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee_shift/get_week_dates/';

		return instance()(
			{
				url: api,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	sendScheduleImage(base64,empID,associates) {
		const associatesList = JSON.stringify(associates);
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'employee_shift/email_schedule/';
		const formData = new FormData();
		formData.append('base64Image', base64);
		formData.append('associate_id', empID);
		formData.append('associate_list', associatesList);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
		.catch((e) => ExceptionHandling.HandleErrors(e))
		.finally(() => {
			store.dispatch(ActionTypes.PROGRESS_BAR, false);
		})
	}
}