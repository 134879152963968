// vue.config.js
module.exports = {
  //FOR TRAIN
  publicPath: process.env.NODE_ENV === 'production' ? '/' : '/',

  //FOR STITCHIT
  //publicPath: process.env.NODE_ENV === 'production' ? '/pos' : '/',

  configureWebpack:{
    performance: {
      hints: false
    },
    optimization: {
      splitChunks: {
        minSize: 10000,
        maxSize: 250000,
      }
    }
},
chainWebpack: (config) => {
  config.plugins.delete('prefetch');
}
}
