import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class ScheduleService {

	getBaseURL() {
		return config.SERVER_URL;
	}

	//ADD SCHEDULE NAME
	addSchedule(scheduleName) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'schedule/add_schedule/';
		const formData = new FormData();
		formData.append('schedule_name', scheduleName);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//ASSIGN SCHEDULE
	assignSchedule(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'schedule/assign_branch_schedule/';
		const formData = new FormData();

		const storeCode = [];

		postObj.store.forEach(function (value) {
			storeCode.push(value.code);
		});

		formData.append('schedule_id', postObj.schedule.id);
		formData.append('assign_type', postObj.type.key);
		formData.append('branches', JSON.stringify(storeCode));
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//GET SCHEDULE DATA
	getAssignScheduleData() {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'schedule/assign_schedule_data/';

		return instance()(
			{
				url: api,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//GET SCHEDULE DATA
	getScheduleList() {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'schedule/get_all_schedule/';

		return instance()(
			{
				url: api,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//ASSIGN SCHEDULE
	getScheduleStores(scheduleID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'schedule/get_all_schedule_branches/';
		const formData = new FormData();

		formData.append('schedule_id', scheduleID);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	//ASSIGN SCHEDULE
	changeScheduleStatus(scheduleID,status) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'schedule/change_status/';
		const formData = new FormData();

		formData.append('id', scheduleID);
		formData.append('status', status);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	//ASSIGN SCHEDULE
	makeDefault(scheduleID,code) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'schedule/set_default/';
		const formData = new FormData();

		formData.append('schedule_id', scheduleID);
		formData.append('code', code);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	removeStoreSchedule(scheduleID,code) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'schedule/remove_schedule/';
		const formData = new FormData();

		formData.append('schedule_id', scheduleID);
		formData.append('code', code);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//ASSIGN SCHEDULE
	editSchedule(scheduleID,scheduleName) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'schedule/edit_schedule/';
		const formData = new FormData();

		formData.append('schedule_id', scheduleID);
		formData.append('schedule_name', scheduleName);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
		.catch((e) => ExceptionHandling.HandleErrors(e))
		.finally(() => {
			store.dispatch(ActionTypes.PROGRESS_BAR, false);
		})
	}
}