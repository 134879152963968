import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class RegisterService {

	getBaseURL() {
		return config.SERVER_URL;
	}

	getRegisters(searchForm) {

		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'cash_register/index/';

		const formData = new FormData();
		formData.append('date1', searchForm.date1);
		formData.append('date2', searchForm.date2);

		const storeCodes = [];

		searchForm.assignedBranchId.forEach(function (value) {
			storeCodes.push(value.code);
		});

		formData.append('branches', JSON.stringify(storeCodes));

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getReconPaymentList(registerID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'cash_register/register_recon/';
		const formData = new FormData();
		formData.append('register_id', registerID);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getOpenDawer(registerID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'cash_register/update_cash_register/';
		const formData = new FormData();
		formData.append('register_id', registerID);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	acceptDawer(postObj, ManagerPIN, totalBalance) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'cash_register/save_todays_balance/';
		const formData = new FormData();
		formData.append('total_hundreds', postObj.totalHundreds);
		formData.append('total_fifties', postObj.totalFifties);
		formData.append('total_twenties', postObj.totalTwenties);
		formData.append('total_tens', postObj.totalTens);
		formData.append('total_fives', postObj.totalFives);
		formData.append('total_coins_twos', postObj.totalCoinsTwos);
		formData.append('total_coins_ones', postObj.totalCoinsOnes);
		formData.append('total_coins_two_five', postObj.totalCoinsTwoFive);
		formData.append('total_coins_one_zero', postObj.totalCoinsOneZero);
		formData.append('total_coins_zero_five', postObj.totalCoinsZeroFive);
		formData.append('manager_id', ManagerPIN);
		formData.append('total_balance', totalBalance);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	updateOpenDay(postObj, ManagerPIN, totalBalance, registerId) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'cash_register/update_todays_balance/';
		const formData = new FormData();
		formData.append('register_id', registerId);
		formData.append('total_hundreds', postObj.totalHundreds);
		formData.append('total_fifties', postObj.totalFifties);
		formData.append('total_twenties', postObj.totalTwenties);
		formData.append('total_tens', postObj.totalTens);
		formData.append('total_fives', postObj.totalFives);
		formData.append('total_coins_twos', postObj.totalCoinsTwos);
		formData.append('total_coins_ones', postObj.totalCoinsOnes);
		formData.append('total_coins_two_five', postObj.totalCoinsTwoFive);
		formData.append('total_coins_one_zero', postObj.totalCoinsOneZero);
		formData.append('total_coins_zero_five', postObj.totalCoinsZeroFive);
		formData.append('manager_id', ManagerPIN);
		formData.append('total_balance', totalBalance);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	updateCloseDay(postObj, ManagerPIN, totalBalance, registerId) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'cash_register/update_todays_end_balance/';
		const formData = new FormData();
		formData.append('end_day_id', registerId);
		formData.append('total_hundreds', postObj.totalHundreds);
		formData.append('total_fifties', postObj.totalFifties);
		formData.append('total_twenties', postObj.totalTwenties);
		formData.append('total_tens', postObj.totalTens);
		formData.append('total_fives', postObj.totalFives);
		formData.append('total_coins_twos', postObj.totalCoinsTwos);
		formData.append('total_coins_ones', postObj.totalCoinsOnes);
		formData.append('total_coins_two_five', postObj.totalCoinsTwoFive);
		formData.append('total_coins_one_zero', postObj.totalCoinsOneZero);
		formData.append('total_coins_zero_five', postObj.totalCoinsZeroFive);
		formData.append('manager_id', ManagerPIN);
		formData.append('total_balance', totalBalance);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	updateItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Services/edit_service/';
		const formData = new FormData();
		formData.append('edit_brand_id', postObj.id);
		formData.append('brand_name', postObj.name);
		formData.append('priority', postObj.priority);
		formData.append('service_picture_hide', postObj.picture);
		formData.append('service_picture', postObj.picture);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	savePaymentDetails(registerID, ManagerID, registerList, endBalance, multiplier, diff) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'cash_register/update_payment_summery/';
		const formData = new FormData();

		formData.append('register_id', registerID);
		formData.append('notes', registerList.notes);
		formData.append('actual_cash', registerList.actualCash);
		formData.append('actual_tip', registerList.actualTipsCollected);
		formData.append('actual_visa', registerList.actualVisa);
		formData.append('actual_master', registerList.actualMasterCard);
		formData.append('actual_amex', registerList.actualAMEX);
		formData.append('actual_discover', registerList.actualDiscover);
		formData.append('actual_gift_card', registerList.actualGiftCard);
		formData.append('actual_debit', registerList.actualDebitCard);
		formData.append('manager_id', ManagerID);
		formData.append('total_end_balance_val', endBalance);
		formData.append('total_diff', diff);
		formData.append('total_hundreds', multiplier.totalHundreds);
		formData.append('total_fifties', multiplier.totalFifties);
		formData.append('total_twenties', multiplier.totalTwenties);
		formData.append('total_tens', multiplier.totalTens);
		formData.append('total_fives',multiplier.totalFives);
		formData.append('total_coins_twos', multiplier.totalCoinsTwos);
		formData.append('total_coins_ones', multiplier.totalCoinsOnes);
		formData.append('total_coins_two_five', multiplier.totalCoinsTwoFive);
		formData.append('total_coins_one_zero', multiplier.totalCoinsOneZero);
		formData.append('total_coins_zero_five', multiplier.totalCoinsZeroFive);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	openCashDrawer(drawerObj, source) {
		//SHOW LOADING
		const store = useStore();

		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'open_drawer/open_store_drawer/';
		const formData = new FormData();

		formData.append('employee_id', drawerObj.associateID);
		formData.append('source', source);
		formData.append('reason', drawerObj.reason);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	submitDrawer(registerID) {
		//SHOW LOADING
		const store = useStore();

		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'cash_register/close_drawer/';
		const formData = new FormData();

		formData.append('register_id', registerID);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getPreviousDayAmount() {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'cash_register/previous_amount/';

		return instance()(
			{
				method: 'get',
				url: api
			}
		)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	closeManagerRegisters(registerID) {
		//SHOW LOADING
		const store = useStore();

		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'cash_register/close_drawer/';
		const formData = new FormData();

		formData.append('register_id', registerID);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	openManagerRegisters(registerID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'cash_register/reopen_drawer/';
		const formData = new FormData();

		formData.append('register_id', registerID);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getBranchBasic() {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'announcement/get_branch_basic/';
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}