import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class Customer {

	getBaseURL() {
		return config.SERVER_URL;
	}

	getItems(keyword) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'customer/search_customer/';
		const formData = new FormData();
		formData.append('search_customer', keyword);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			})
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	openDialog() {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'customer/customer_details/';
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//SAVE ASSOCIATE IN DATABASE
	saveItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'customer/register_customer/';
		const formData = new FormData();

		formData.append('first_name', postObj.firstName);
		formData.append('last_name', postObj.lastName);
		formData.append('customer_email', postObj.email);
		formData.append('contact', postObj.phone1);
		formData.append('phone', postObj.homePhone);
		formData.append('company_name', postObj.misc);

		formData.append('customer_type', postObj.customerType.value);
		formData.append('customer_cls', postObj.class.value);
		formData.append('gender', postObj.gender.value);
		formData.append('state', postObj.stateProvince);

		formData.append('address', postObj.address);
		formData.append('city', postObj.city);
		formData.append('zip', postObj.zipCode);
		formData.append('complex', postObj.complex);
		formData.append('pst_exempt', postObj.provincialRate);
		formData.append('hst_exempt', postObj.canadaRate);
		formData.append('exempt_1', postObj.exempt1);
		formData.append('exempt_2', postObj.exempt2);
		formData.append('key_tags', postObj.keyTags);
		formData.append('urgent_memo', postObj.urgentMemo);
		formData.append('birthday', postObj.birthday);

		if (postObj.gender.value == "Male") {
			formData.append('male_neck', postObj.maleNeck);
			formData.append('male_full_chest', postObj.maleFullChest);
			formData.append('male_front_chest_area', postObj.maleFrontChestArea);
			formData.append('male_back_chest', postObj.maleBackChest);
			formData.append('male_full_shoulder', postObj.maleFullShoulderWidth);
			formData.append('male_blazer_sleeve', postObj.maleBlazerSleeve);
			formData.append('male_bicep', postObj.maleBicep);
			formData.append('male_wrist', postObj.maleWrist);
			formData.append('male_trouser_outseam', postObj.maleTrouserOutseam);
			formData.append('male_thigh', postObj.maleThigh);
			formData.append('male_knee', postObj.maleKnee);
			formData.append('male_hips', postObj.maleHipSeat);
			formData.append('male_half_back_lenght', postObj.maleHalfBackLength);
		}

		if (postObj.gender.value == "Female") {
			formData.append('female_neck', postObj.femaleNeck);
			formData.append('female_over_brust', postObj.femaleOverBrust);
			formData.append('female_brust', postObj.femaleBrust);
			formData.append('female_under_bust', postObj.femaleUnderBrust);
			formData.append('female_waist', postObj.femaleWaist);
			formData.append('female_hips', postObj.femaleHips);
			formData.append('female_neck_heel', postObj.femaleNeckHeal);
			formData.append('female_arm_length', postObj.femaleArmLenght);
			formData.append('female_shoulder_seam', postObj.femaleShoulderSeam);
			formData.append('female_bicep', postObj.femaleBicep);
			formData.append('female_forearm', postObj.femaleForearm);
			formData.append('female_wrist', postObj.femaleWrist);
		}

		formData.append('customer_mood', postObj.cusMood);
		formData.append('customer_comments', postObj.cusComments);
		formData.append('enable_points', postObj.pointFeature);
		formData.append('promotion', postObj.promoNotification);
		formData.append('email_notification', postObj.emailNotification);
		formData.append('sms_notification', postObj.textMessage);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	//UPDATE ASSOCIATE
	updateItem(postObj,reviews) {

		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'customer/update_customer/';
		const formData = new FormData();

		formData.append('customer_id', postObj.customerId);
		formData.append('first_name', postObj.firstName);
		formData.append('last_name', postObj.lastName);
		formData.append('customer_email', postObj.email);
		formData.append('contact', postObj.phone1);
		formData.append('phone', postObj.homePhone);
		formData.append('company_name', postObj.misc);

		formData.append('customer_type', postObj.customerType.value);
		formData.append('customer_cls', postObj.class.value);
		formData.append('gender', postObj.gender.value);
		formData.append('state', postObj.stateProvince);

		formData.append('address', postObj.address);
		formData.append('city', postObj.city);
		formData.append('zip', postObj.zipCode);
		formData.append('complex', postObj.complex);
		formData.append('pst_exempt', postObj.provincialRate);
		formData.append('hst_exempt', postObj.canadaRate);
		formData.append('exempt_1', postObj.exempt1);
		formData.append('exempt_2', postObj.exempt2);
		formData.append('key_tags', postObj.keyTags);
		formData.append('urgent_memo', postObj.urgentMemo);
		formData.append('birthday', postObj.birthday);
		formData.append('store_credit', postObj.pointEarned);
		if (postObj.gender.value == "Male") {
			formData.append('male_neck', postObj.maleNeck);
			formData.append('male_full_chest', postObj.maleFullChest);
			formData.append('male_front_chest_area', postObj.maleFrontChestArea);
			formData.append('male_back_chest', postObj.maleBackChest);
			formData.append('male_full_shoulder', postObj.maleFullShoulderWidth);
			formData.append('male_blazer_sleeve', postObj.maleBlazerSleeve);
			formData.append('male_bicep', postObj.maleBicep);
			formData.append('male_wrist', postObj.maleWrist);
			formData.append('male_trouser_outseam', postObj.maleTrouserOutseam);
			formData.append('male_thigh', postObj.maleThigh);
			formData.append('male_knee', postObj.maleKnee);
			formData.append('male_hips', postObj.maleHipSeat);
			formData.append('male_half_back_lenght', postObj.maleHalfBackLength);
		}

		if (postObj.gender.value == "Female") {
			formData.append('female_neck', postObj.femaleNeck);
			formData.append('female_over_brust', postObj.femaleOverBrust);
			formData.append('female_brust', postObj.femaleBrust);
			formData.append('female_under_bust', postObj.femaleUnderBrust);
			formData.append('female_waist', postObj.femaleWaist);
			formData.append('female_hips', postObj.femaleHips);
			formData.append('female_neck_heel', postObj.femaleNeckHeal);
			formData.append('female_arm_length', postObj.femaleArmLenght);
			formData.append('female_shoulder_seam', postObj.femaleShoulderSeam);
			formData.append('female_bicep', postObj.femaleBicep);
			formData.append('female_forearm', postObj.femaleForearm);
			formData.append('female_wrist', postObj.femaleWrist);
		}

		formData.append('customer_mood', postObj.cusMood);
		formData.append('customer_comments', postObj.cusComments);
		formData.append('enable_points', postObj.pointFeature);
		formData.append('promotion', postObj.promoNotification);
		formData.append('email_notification', postObj.emailNotification);
		formData.append('sms_notification', postObj.textMessage);
		formData.append('reviews', JSON.stringify(reviews));

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	// //SAVE CUSTOMER REVIEW
	// saveCustomerReviews(postObj) {
	// 	//SHOW LOADING
	// 	const store = useStore();
	// 	store.dispatch(ActionTypes.PROGRESS_BAR, true);
	// 	const api = 'customer/save_customer_reviews/';
	// 	const formData = new FormData();

	// 	formData.append('customer_id', postObj.customerId);
	// 	formData.append('comments', postObj.cusComments);
	// 	formData.append('mood', postObj.cusMood);

	// 	return instance()(
	// 		{
	// 			method: 'post',
	// 			url: api,
	// 			data: formData,
	// 		}
	// 	).then(res => res.data)
	// 		.catch((e) => ExceptionHandling.HandleErrors(e))
	// 		.finally(() => {
	// 			store.dispatch(ActionTypes.PROGRESS_BAR, false);
	// 		})
	// }

	// //ADD CUSTOMER CREDIT
	// addCustomerCredit(postObj) {
	// 	//SHOW LOADING
	// 	const store = useStore();
	// 	store.dispatch(ActionTypes.PROGRESS_BAR, true);
	// 	const api = 'customer/add_customer_credit/';
	// 	const formData = new FormData();

	// 	formData.append('customer_id', postObj.customerId);
	// 	formData.append('store_credit', postObj.cusCredit);

	// 	return instance()(
	// 		{
	// 			method: 'post',
	// 			url: api,
	// 			data: formData,
	// 		}
	// 	).then(res => res.data)
	// 		.catch((e) => ExceptionHandling.HandleErrors(e))
	// 		.finally(() => {
	// 			store.dispatch(ActionTypes.PROGRESS_BAR, false);
	// 		})
	// }

	//GET SINGLE ITEM
	getItem(id) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'customer/edit_customer/' + id;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	// cHANGE ASSOCIATE STATUS
	changeStatus(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'customer/change_status/' + data.customerId + '/' + data.status;
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}