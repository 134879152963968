import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';


export default class BuyBackService {

	getBaseURL() {
		return config.SERVER_URL
	}

	getNeddle(customerID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'single_checkout/get_needle_points/';

		const formData = new FormData();
		formData.append('cus_id', customerID);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getBBDetails(receiptID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'single_checkout/get_buy_back_option/';

		const formData = new FormData();
		formData.append('inv_id', receiptID);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getSalesItemID(itemID, receiptID) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'single_checkout/get_single_buy_back/';

		const formData = new FormData();
		formData.append('inv_id', receiptID);
		formData.append('sales_id', itemID);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	verifyPINS(managerId, employeeId) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'single_checkout/check_employee_manager_credentials_buyback/';

		const formData = new FormData();
		formData.append('manager_id', managerId);
		formData.append('employee_id', employeeId);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	sendBBReq(checkedItems,bbData, PaymentList, customerID, receiptID,drawerStatus) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'single_checkout/add_buy_back/';

		const formData = new FormData();

		formData.append('checkedItems', JSON.stringify(checkedItems));
		formData.append('customer_name', bbData.customerName);
		formData.append('inv_id', receiptID);
		formData.append('cus_id', customerID);
		formData.append('address', bbData.address);
		formData.append('cheque_need_customer', bbData.chequeNeedCustomer);
		formData.append('payout_in_store', bbData.payoutInStore);
		formData.append('total_of_garments', bbData.totalOfGarments);
		formData.append('refund_alteration_cus', bbData.refundAlterationCus);
		formData.append('fill_in_price', bbData.fillInPrice);
		formData.append('total_to_paid', bbData.totalToPaid);
		formData.append('reason', bbData.reason);
		formData.append('garment_description', bbData.garmentDescription);
		formData.append('receipt_in_mail', bbData.receiptInMail);
		formData.append('garment_in_mail', bbData.garmentInMail);
		formData.append('manager_who_approved', bbData.managerWhoApproved);
		formData.append('employee_id', bbData.employeeId);
		formData.append('fit_expert_name', bbData.fitExpertName);
		formData.append('sew_expert_name', bbData.sewExpertName);
		formData.append('associate_responsible', bbData.associateResponsible);
		formData.append('payment_list', JSON.stringify(PaymentList));
		formData.append('drawer_status', drawerStatus);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	sendRefundReq(refundData, PaymentList, orderList, receiptID, totalRefunded,drawerStatus) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'single_checkout/create_refund/';

		const formData = new FormData();
		formData.append('order_items', JSON.stringify(orderList));
		formData.append('invoice_id', receiptID);
		formData.append('total_refunded', totalRefunded);
		formData.append('refund_reason', refundData.reason);
		formData.append('manager_id', refundData.managerWhoApproved);
		formData.append('employee_id', refundData.employeeId);
		formData.append('drawer_status', drawerStatus);
		formData.append('payment_list', JSON.stringify(PaymentList));
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}