import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class UpChargeList {

	getBaseURL() {
		return config.SERVER_URL;
	}

	getItems(start) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'upcharge/index/' + start;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	saveItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'upcharge/add_upcharge/';
		const formData = new FormData();
		formData.append('upcharge_name', postObj.name);
		formData.append('opt_name', postObj.option);
		formData.append('value_name', postObj.chargeValue);
		if (postObj.optApply == 'all') {
			formData.append('opt_apply', postObj.optApply[0]);
			formData.append('opt_apply_id', postObj.optApply[0]);
		} else if (postObj.optApply) {
			formData.append('opt_apply', postObj.optApply.type);
			formData.append('opt_apply_id', postObj.optApply.id);
		}
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	updateItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'upcharge/edit_upcharge/';
		const formData = new FormData();
		formData.append('upcharge_id', postObj.id);
		formData.append('upcharge_name', postObj.name);
		formData.append('opt_name', postObj.option);
		formData.append('value_name', postObj.chargeValue);
		if (postObj.optApply == 'all') {
			formData.append('opt_apply', postObj.optApply[0]);
			formData.append('opt_apply_id', postObj.optApply[0]);
		} else if (postObj.optApply) {
			formData.append('opt_apply', postObj.optApply.type);
			formData.append('opt_apply_id', postObj.optApply.id);
		}

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getItem(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'upcharge/single_upcharge/' + data.id;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	changeStatus(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'upcharge/change_status/' + data.status + '/' + data.id;
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getSearchedUpchargeLists(keyword) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'upcharge/search_for_upcharge/';

		const formData = new FormData();
		formData.append('keyword', keyword);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}