
import Toaster from '../helpers/Toaster'

export default class ExceptionHandling {

	static HandleErrors(error) {

		const toaster = new Toaster()
		if (error.response) {

			toaster.showError('Response from the api server get failed with status ' + error.response.status);
		}
		else if (error.request) {
			toaster.showError('Request to the api server get failed. Seems like server is not responding ' + error.request.status);
		}
		else {
			toaster.showError('Could not process the request');
		}
	}
}