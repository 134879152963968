import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class StoreExpense {

	getBaseURL() {
		return config.SERVER_URL;
	}

	getItems(searchForm) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'store_expense/index/';
		const formData = new FormData();
		formData.append('date1', searchForm.date1);
		formData.append('date2', searchForm.date2);
		formData.append('filter', searchForm.filter.value);
		return instance()(
				{
					method: 'post',
					url: api,
					data: formData,
				}
			)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	saveItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'store_expense/add_store_expense/';
		const formData = new FormData();
		formData.append('employee_id', postObj.associateID);
		formData.append('amount', postObj.expenseAmount);
		formData.append('reason', postObj.expenseReason);
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	updateItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'store_expense/update_store_expense/';
		const formData = new FormData();
		formData.append('expense_id', postObj.id);
		formData.append('employee_id', postObj.associateID);
		formData.append('amount', postObj.expenseAmount);
		formData.append('reason', postObj.expenseReason);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getItem(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'store_expense/single_store_expense/' + data.id;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}