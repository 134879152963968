import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class Surves {

	getBaseURL() {
		return config.SERVER_URL;
	}

	getItems(start) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'survey/index/' + start;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	saveItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'survey/add_survey/';
		const formData = new FormData();

		const storeCode = [];

		postObj.filterStores.forEach(function (value) {
			storeCode.push(value.code);
		});

		formData.append('survey_question', postObj.question);
		formData.append('option_1', postObj.opt1);
		formData.append('option_2', postObj.opt2);
		formData.append('option_3', postObj.opt3);
		formData.append('option_4', postObj.opt4);
		formData.append('option_5', postObj.opt5);
		formData.append('answer', postObj.correctOpt.key);
		formData.append('type', postObj.destination.key);
		formData.append('branches', JSON.stringify(storeCode));
		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	updateItem(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'survey/edit_survey/';
		const formData = new FormData();

		const storeCodes = [];
		postObj.filterStores.forEach(function (value) {
			storeCodes.push(value.code);
		});

		formData.append('survey_id', postObj.id);
		formData.append('survey_question', postObj.question);
		formData.append('option_1', postObj.opt1);
		formData.append('option_2', postObj.opt2);
		formData.append('option_3', postObj.opt3);
		formData.append('option_4', postObj.opt4);
		formData.append('option_5', postObj.opt5);
		formData.append('answer', postObj.correctOpt.key);
		formData.append('type', postObj.destination.key);
		formData.append('branches', JSON.stringify(storeCodes));

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getItem(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'survey/single_survey/' + data.survey_id;
		return instance().get(api)
			.then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	changeStatus(data) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'survey/change_status/' + data.status + '/' + data.id;
		return instance().get(api).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	getSearchedSurves(keyword) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'survey/search_for_survey/';

		const formData = new FormData();
		formData.append('keyword', keyword);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
}