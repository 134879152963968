import ExceptionHandling from './ExceptionHandling.js'
import { useStore, ActionTypes } from "../store";
import instance from './index';

export default class StoreReports {

	getBaseURL() {
		return config.SERVER_URL
	}

	storeList() {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/stores';
		return instance().get(api)
		.then(res => res.data)
		.catch((e) => ExceptionHandling.HandleErrors(e))
		.finally(() => {
			store.dispatch(ActionTypes.PROGRESS_BAR, false);
		})
	}

	associateReport(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/associate_report';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('type', postObj.empFilter);
		formData.append('store_id', postObj.filterBranch);
		formData.append('associate_id', postObj.id);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	drycleanInvoices(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/dryclean_invoices';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	couponInvoices(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/coupon_invoices';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	corporateInvoices(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/corporate_invoices';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	productInvoices(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/product_invoices';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	superCreaseInvoices(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/super_crease_invoices';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	customerRelated(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/customer_related';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	customerCorporateType(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/customer_corporate_type';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);
		formData.append('corporate_type', postObj.corporateType);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	customerReport(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/customer_report';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	referralInvoices(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/referral_invoices';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	refundInvoices(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/refund_invoices';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	buybackInvoices(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/buyback_invoices';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	timeSheetReport(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/time_sheet_report';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	buybackChangeStatus(id,status) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/change_buyback_status';
		const formData = new FormData();

		formData.append('id', id);
		formData.append('status', status);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	performanceReport(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/performance';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);
		formData.append('condition', postObj.condition);
		formData.append('dimension', postObj.dimension);
		formData.append('sort', postObj.sort);
		formData.append('amount_value', postObj.amountValue);
		formData.append('limit', postObj.limit);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	productivityReport(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/productivity';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);
		formData.append('condition', postObj.condition);
		formData.append('dimension', postObj.dimension);
		formData.append('sort', postObj.sort);
		formData.append('amount_value', postObj.amountValue);
		formData.append('limit', postObj.limit);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	weeklyPaymentReport(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/weekly_payment';
		const formData = new FormData();

		formData.append('week_date', postObj.weekDate);
		formData.append('store_id', postObj.filterBranch);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	itemsReport(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/items_report';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	salesSummaryReport(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'Store_report/sales_summary_report';
		const formData = new FormData();

		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);
		formData.append('filter', postObj.filter);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	transactionsReport(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'store_report/transaction_report/';
		const formData = new FormData();
		formData.append('filter', postObj.filter);
		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('report_type', postObj.reportType);
		formData.append('store_id', postObj.filterBranch);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	stockReport(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'store_report/stock_report/';
		const formData = new FormData();
		formData.append('filter', postObj.filter);
		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('report_type', postObj.reportType);
		formData.append('store_id', postObj.filterBranch);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	dailyService(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'store_report/daily_service/';
		const formData = new FormData();
		formData.append('filter', postObj.filter);
		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	dailySales(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'store_report/daily_sales/';
		const formData = new FormData();
		formData.append('filter', postObj.filter);
		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	cashSummary(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'store_report/cash_summary/';
		const formData = new FormData();
		formData.append('filter', postObj.filter);
		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	tenderSummary(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'store_report/tender_summary/';
		const formData = new FormData();
		formData.append('filter', postObj.filter);
		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('store_id', postObj.filterBranch);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}
	
	flashReport(postObj) {
		//SHOW LOADING
		const store = useStore();
		store.dispatch(ActionTypes.PROGRESS_BAR, true);
		const api = 'store_report/flash_report/';
		const formData = new FormData();
		formData.append('filter', postObj.filter);
		formData.append('date1', postObj.dateFrom);
		formData.append('date2', postObj.dateTo);
		formData.append('region_id', postObj.filterRegion);

		return instance()(
			{
				method: 'post',
				url: api,
				data: formData,
			}
		).then(res => res.data)
			.catch((e) => ExceptionHandling.HandleErrors(e))
			.finally(() => {
				store.dispatch(ActionTypes.PROGRESS_BAR, false);
			})
	}

	
}